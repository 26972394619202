.experience-container {
  background-image: url("https://res.cloudinary.com/dn11uqgux/image/upload/v1633988844/project-setup-test/Untitled_design_8_rp2bde.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .title {
    margin-top: 55px;
    text-decoration: underline;
    margin-bottom: 25px;
  }
  img {
    height: 125px;
    // border: 1px solid #f0f0f5;
    border-radius: 3px;
  }
}

.experience-text {
  margin: 0 60px;
  max-width: 860px;
}

.soho,
.feast-it,
.ga {
  display: flex;
  align-items: center;
  padding: 5px 35px;
  border-radius: 10px;
  max-width: 840px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  margin-bottom: 10px;
  // margin-right: 60px;
  .subtitle {
    font-size: 25px;
    margin-bottom: 10px;
  }
  div {
    margin: 0 10px;
  }
  .text {
    line-height: 150%;
    text-align: justify;
    margin-bottom: 5px;
  }
}

.soho {
  background-color: $light-blue;
}

.feast-it {
  background-color: $light-green;
}

.ga {
  background-color: $light-pink;
}

@media only screen and (max-width: 655px) {
  .soho,
  .soho-two,
  .ga {
    flex-direction: column;
    .subtitle {
      text-align: center;
    }
    img {
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 540px) {
  .experience-text {
    margin-right: 10px;
  }
  .soho,
  .soho-two,
  .ga {
    .subtitle {
      font-size: 20px;
    }
  }
}
