@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

* {
  font-family: 'Manrope', sans-serif;
  margin: 0;
  padding: 0;
}

$light-pink: #f294c0aa;
$highlighted-pink: #f294c0;
$light-blue: #0c70f2aa;
$highlighted-blue: #0c70f2;
$light-green: #52d95baa;
$highlighted-green: #52d95b;
$light-yellow: #f2cb05aa;
$highlighted-yellow: #f2cb05;
$light-orange: #f28f16aa;
$highlighted-orange: #f28f16;

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 45px;
}

.subtitle {
  font-size: 30px;
}

@media only screen and (max-width: 540px) {
  .title {
    font-size: 35px;
    text-align: center;
  }
}

@media only screen and (max-width: 475px) {
  .container {
    margin: 0;
  }
}