.hero-container {
  background-image: url('https://res.cloudinary.com/dn11uqgux/image/upload/v1633875888/project-setup-test/Untitled_design_aid4gp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  .home {
    div {
      padding: 10px;
      border-radius: 10px;
      max-width: 840px;
      background-color: $light-green;
      box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    }
  }
}

.profile-image {
  img {
    border-radius: 50%;
    height: 225px;
    width: auto;
    margin-left: 30px;
    filter: grayscale(100%);
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  }
}

@media only screen and (max-width: 645px) {
  .hero {
    flex-direction: column;
    .home {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 10px;
      }
      p {
        text-align: center;
      }
    }
    .profile-image {
      margin: 30px 0 0 -50px;
    }
  }
}
