.contacts-container {
  background-image: url('https://res.cloudinary.com/dn11uqgux/image/upload/v1633988575/project-setup-test/Untitled_design_7_fycn8p.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .title {
    text-decoration: underline;
    // text-align: left;
    width: 100%;
    // padding: 0 60px;
    margin-bottom: 25px;
  }
}

.contact-text {
  text-align: center;
  margin: 0 60px;
  max-width: 840px;
  a {
    text-decoration: none;
    color: black;
  }
  div {
    display: flex;
    align-items: center;
    padding: 5px 35px;
    border-radius: 10px;
    max-width: 840px;
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    min-height: 75px;
    .subtitle {
      margin-left: 10px;
      // display: inline-block;
    }
    .subtitle:hover {
      border-bottom: 2px black solid;
    }
    img {
      height: 50px;
    }
  }
  .email {
    background-color: $light-pink;
  }
  .linkdin {
    background-color: $light-blue;
  }
  .github {
    background-color: $light-green;
  }
}

@media only screen and (max-width: 700px) {
  .contact-text {
    div {
      flex-direction: column;
      img {
        height: 40px;
      }
      .subtitle {
        font-size: 17px;
        margin: 5px auto;
      }
    }
    
  }
}

@media only screen and (max-width: 540px) {
  .contact-text {
    margin-right: 10px;
  }
}